$(function() {
  var pagetop = $('#page_top');
  pagetop.hide();//デフォルトは非表示

  //スクロールしたら表示
  $(window).scroll(function(){
    if ($(this).scrollTop() > 1000) {
      pagetop.fadeIn(1000);
    } else {
      pagetop.fadeOut(1000);
    }
  });
  //クリックしたらページトップにスクロール
  pagetop.click(function(){
    $('body, html').animate({scrollTop:0}, 500);
    return false;
  });
});

